@import "custom/custom_variables";
@import "lib/uikit/mixins";
@import "lib/uikit/variables";
@import "lib/uikit/theme/import";
@import "lib/uikit/components/import";
@import "custom/nav";
@import "custom/base";
@import "custom/button";
@import "custom/responsive";
@import "custom/tabs";
@import "custom/widgets";

.tm-header-transparent {
  position: absolute;
  z-index: 980;
  left: 0;
  right: 0px;
}

