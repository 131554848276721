.uk-navbar-nav > li > a,
.uk-navbar-item,
.uk-navbar-toggle {
  text-transform: uppercase;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 600;
}

.uk-navbar-nav > li:hover > a,
.uk-navbar-nav > li > a:focus,
.uk-navbar-nav > li > a.uk-open {
  color: $main-color-primary;
}

.cbf-navbar-thrid {
  background: $main-color-primary;
  z-index: 100;
  .uk-navbar-dropdown-nav {
    > li {
      > a {
        color: #fff;
      }
    }
  }
  &:after {
    right: 50%;
    top: -3%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(232, 94, 25, 0);
    border-bottom-color: #e31e24;
    border-width: 5px;
    margin-top: -5px;
  }
}
span.cbf-nav-icon.uk-icon {
  float: right;
}

.uk-navbar-sticky {
  border-bottom: 2px solid #ff0000;
}

.cbf-lang-dropdown {
  background: rgba(255,255,255,0);
  width: 50px;
  max-width: 50px;
  min-width: 50px;
  ul {
    padding-left: 0px;
    li {
      list-style: none;
    }
  }
}

.uk-navbar-sticky .cbf-lang-dropdown {
  background: rgba(255,255,255,1);
  a {
    color: #999;
  }
}

.cbf-slideshow-box {
  p, h3 {
    margin: 0px;
  }
  h3 {
    font-size: 15px;
  }
  a {
    color: #ffffff;
    font-weight:bold;
  }
  p {
    color: #97a3b8;
    font-size: 12px;
  }
}