.nav-tabs, .uk-tab {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -20px;
  padding: 0;
  list-style: none;
}

.nav-tabs > li, .uk-tab > * {
  -ms-flex: none;
  -webkit-flex: none;
  flex: none;
  padding-left: 20px;
  position: relative;
}

.nav-tabs > li > a {
  display: block;
  text-align: center;
  padding: 10px 10px;
  color: #a3a4a8;
  -webkit-transition: .1s ease-in-out;
  transition: .1s ease-in-out;
  -webkit-transition-property: color,background-color,border-color,box-shadow;
  transition-property: color,background-color,border-color,box-shadow;
  font-size: 15px;
  line-height: 20px;
  font-weight: 300;
  text-transform: none;
  letter-spacing: 0;
  border-bottom: 3px solid transparent;
}

.uk-tab > * > a {
  display: block;
  text-align: center;
  padding: 10px 10px;
  color: #a3a4a8;
  -webkit-transition: .1s ease-in-out;
  transition: .1s ease-in-out;
  -webkit-transition-property: color,background-color,border-color,box-shadow;
  transition-property: color,background-color,border-color,box-shadow;
  font-size: 15px;
  line-height: 20px;
  font-weight: 300;
  text-transform: none;
  letter-spacing: 0;
  border-bottom: 3px solid transparent;
  &:focus, &:hover {
    color: #737373;
    text-decoration: none;
    border-color: #f03f37;
  }
}

.nav-tabs > .active > a {
  color: #242424;
  border-color: #f03f37;
}

.uk-tab > {
  .uk-active > a {
    color: #242424;
    border-color: #f03f37;
  }
  .uk-disabled > a {
    color: #a3a4a8;
  }
}

.uk-tab-bottom > * > a {
  border-top: 3px solid transparent;
  border-bottom: 0 none;
}

.uk-tab-left, .uk-tab-right {
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  margin-left: 0;
}

.uk-tab-left > *, .uk-tab-right > * {
  padding-left: 0;
}

.uk-tab-left > * > a {
  text-align: left;
  padding: 10px 10px;
  border-right: 3px solid transparent;
  border-bottom: 0 none;
}

.uk-tab-right > * > a {
  text-align: left;
  padding: 10px 10px;
  border-left: 3px solid transparent;
  border-bottom: 0 none;
}

.uk-tab .uk-dropdown {
  margin-left: 30px;
}