.cbf-image {
  img {
    margin: 0px auto;
    display: block;
  }
}

.cbf-category-slider {
  border-top: 4px solid $main-color-primary;
  border-bottom: 4px solid $main-color-primary;
  .cbf-overlay-primary {
    font-weight: bolder;
    color: #fff;
    padding: 5px;
    text-transform: uppercase;
    font-size: 0.775rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  li.uk-active {
    padding-left: 0px;
  }
}

.cbf-image-block {
  h3, .cbf-sub-title {
    color: #fff;
  }

}