
/* Phone landscape and bigger */
@media (max-width: $breakpoint-small) {
  .cbf-card-body {
    background: rgba(47, 46, 110, 1);
  }
}

@media (min-width: $breakpoint-small) {
  .button-container {
    margin-top: 0px;
  }

  .cbf-card-body {
    background: rgba(47, 46, 110, 1);
  }
}

/* Tablet landscape and bigger */
@media (min-width: $breakpoint-medium) {
  .button-container {
    margin-top: -432px;
    margin-bottom: 30px;
  }
}

/* Desktop and bigger */
@media (min-width: $breakpoint-large) {
  .button-container {
    margin-top: -485px;
    margin-bottom: 30px;
  }
}

/* Large screen and bigger */
@media (min-width: $breakpoint-xlarge) {
  .button-container {
    margin-top: -490px;
  }
}