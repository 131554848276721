$main-color-primary: #e31e24 !default;
$main-color-secondary: #ef7f1a !default;
$main-color-third: #008dd2 !default;
$main-color-third-hover: rgba(0,141,210,.8) !default;
$main-color-fourth: #487a2e !default;
$main-color-fourth-hover: rgba(72,122,46,.8) !default;
$main-color-fifth: #858db9 !default;
$main-color-fifth-hover: rgba(133,141,185,.8) !default;
$main-color-sixth: #858db9 !default;
$main-color-sixth-hover: rgba(0,141,210,.8) !default;
$main-color-seventh: #333276 !default;
$main-color-seventh-hover: rgba(51,50,118,.8) !default;

$global-primary-background: $main-color-primary !default;
$global-secondary-background: $main-color-secondary !default;
$navbar-background: #ffffff !default;
$navbar-dropdown-background: #fff !default;
$global-font-family: Raleway, "Helvetica Neue", Arial, sans-serif !default;
$global-secondary-background: $main-color-seventh !default;

$grey-color: #e8e8e8 !default;
$main-color-black: #333 !default;
$main-color-orange: #E85E19 !default;
$main-color-orange-dark: #F06746 !default;
$main-color-orange-yellow: #FF8E28 !default;
$main-color-orange-yellow-dark: #E89619 !default;
$main-color-yellow: #FFBB1C !default;
$main-grey-color: #5B5B5E !default;

$overlay-primary-background: rgba($main-color-seventh, 0.8) !default;




@mixin transition() {
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

$heading-bullet-border: $main-color-primary;