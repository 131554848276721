.cbf-logo-white {
  min-height: 120px;
  position: absolute;
  left: 10px;
  top: 8px;
}

.cbf-progress {
  background: transparent;
  border: 1px solid $main-color-secondary;
  display: block;
  height: 5px;
  margin: 15px 0 0 0;
  overflow: hidden;
  width: 25%;
}

.cbf-progress-bar {
  background: $main-color-secondary;
  display: block;
  height: 6px;
  margin-left: -100%;
  -webkit-transition: margin 1s linear;
  -moz-transition: margin 1s linear;
  -o-transition: margin 1s linear;
  transition: margin 1s linear;
  width: 100%;
  -webkit-transition-duration: 3s !important;
  -moz-transition-duration: 3s !important;
  -ms-transition-duration: 3s !important;
  -o-transition-duration: 3s !important;
  transition-duration: 3s !important;
  -webkit-transition-property: margin-left;
  -moz-transition-property: margin-left;
  -ms-transition-property: margin-left;
  -o-transition-property: margin-left;
  transition-property: margin-left;

}

.cbf-progress-bar-active {
  margin-left: 0% !important;
}

.cbf-card-body {
  background: rgba(47,46,110,0.7);
  color: #fff;
  h4 {
    color: #fff;
    font-weight:bold;
  }
}

.cbf-section-light-blue {
  background: #f2f4f5;
}

.cbf-section-purple {
  background: $main-color-seventh;
}

.cbf-section-black {
  background: $main-color-black;
}

.cbf-overlay-primary {
  background: $main-color-primary;
  color: #fff;
}

.cbf-section-green {
  background: $main-color-fourth;
  color: #fff;
}

.cbf-section-blue {
  background: $main-color-third;
  color: #fff;
}

.cbf-tabs {
  li {
    a {
      border: 1px solid #333;
      color: #333;
      height: 40px;
      line-height: 40px;
      text-transform: uppercase;
    }
  }
  li.uk-active {
    a {
      border: none;
    }

  }

}

.cbf-shadow-box {
  -webkit-box-shadow: 0 2px 5px 0 rgba(160, 167, 169, 0.3);
  -moz-box-shadow: 0 2px 5px 0 rgba(160, 167, 169, 0.3);
  box-shadow: 0 2px 5px 0 rgba(160, 167, 169, 0.3);
  margin-bottom: 4px;
}

.uk-card-body img {
  height: 201px;
  display: block;
  margin: 0 auto;
  text-align: center;
  vertical-align: middle;
}

.cbf-color-dark {
  color: #aaa;
}

.cbf-slider {
  .uk-heading-primary {
    font-size: 40px;
  }
}

.cbf-icon-list {
  span {
    color: $main-color-primary;
  }
  a {
    color: #a3a4a8;
  }
}

.cbf-blackqoute {
  font-family: Raleway, "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  font-style: italic;
}

.uk-card-default {
  background: #fff;
  color: #4F5260;
  box-shadow: 2px 15px 50px rgba(41,44,61,.1);
}

.uk-card {
  position: relative;
  box-sizing: border-box;
  transition: .1s ease-in-out;
  transition-property: color,background-color,border-color,box-shadow,top;
  border-radius: 2px
}

.uk-divider-small::after {
  content: "";
  display: inline-block;
  width: 100%;
  max-width: 100%;
  border-top: 3px solid #ff0000;
  vertical-align: top;
}


$global-link-color: $main-color-primary !default;



//#333276
//#858db9
//#009846
//#008dd2
//#e31e24
//#ef7f1a