.uk-card {
  .uk-button {
    height: 67px;
    line-height: 67px;
    text-align:center;
    font-family: 'Roboto', Arial, sans-serif;
    font-weight:bold;
    text-transform: uppercase;
    box-shadow: 0 2px 5px 0 rgba(160, 167, 169, 0.3);
    .cbf-button-icon {
      float: left;
      line-height: 65px;
      svg {
        height:30px;
        width: 30px;
      }
    }

    .cbf-nav-icon {
      line-height: 65px;
    }
  }
}

.uk-card-body {
  .cbf-nav-icon {
    svg {
      height:45px;
      width: 45px;
    }
  }
}

.uk-button-third {
  background: $main-color-third;
  color: #fff;
  &:hover {
    color: #fff;
    background: $main-color-third-hover;
  }
}

.uk-button-fourth {
  background: $main-color-fourth;
  color: #fff;
  &:hover {
    color: #fff;
    background: $main-color-fourth-hover;
  }
}

.uk-button-fifth {
  background: $main-color-fifth;
  color: #fff;
  &:hover {
    color: #ffffff;
    background: $main-color-fifth-hover;
  }
}